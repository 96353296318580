*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

html,
body,
#root {
  min-height: 100vh;
}
